<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-card
        v-if="userData"
      >
        <validation-observer
          ref="userRules"
        >
          <!-- form -->
          <b-form>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                  />
                  <h4 class="ml-1 mb-0 font-weight-normal">
                    Admin Information
                  </h4>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="4">
                <b-form-group
                  label="First Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.first_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Last Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.last_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="userData.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                v-if="!adminId"
                sm="4"
              >
                <b-form-group
                  label="Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required|min:6"
                  >
                    <b-form-input
                      v-model="userData.password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Phone Number"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Phone Number"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Instances"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Instances"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedInstances"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="name"
                      multiple
                      :options="instancesOptions"
                      :reduce="option => option.id"
                      :class="{'is-invalid': errors.length > 0 }"
                      @input="selectedInstancesChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group
                  label="Role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <v-select
                      v-model="userData.role_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="display_name"
                      multiple
                      :options="rolesOptions"
                      :reduce="option => option.id"
                      :class="{'is-invalid': errors.length > 0 }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                sm="12"
                class="mt-2 d-flex align-items-center mb-2"
              >
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                />
                <h5 class="mb-0 ml-75 font-weight-normal">
                  Program Visibility
                </h5>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="3"
                class="mb-1"
              >
                <b-form-group
                  label="Show All"
                  label-for="show-all"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="show-all"
                    v-model="programVisibility.show_all"
                    @change="isShowAllChanged"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="!programVisibility.show_all"
                cols="3"
                class="mb-1"
              >
                <b-form-group
                  label="Show Previous"
                  label-for="show-previous"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="show-previous"
                    v-model="programVisibility.show_previous"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="!programVisibility.show_all"
                cols="3"
                class="mb-1"
              >
                <b-form-group
                  label="Show Only Active"
                  label-for="show-only-active"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="show-only-active"
                    v-model="programVisibility.show_only_active"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="!programVisibility.show_all"
                cols="3"
                class="mb-1"
              >
                <b-form-group
                  label="Show Only Certain Programs"
                  label-for="show-only-certain-programs"
                  class="mb-2"
                >
                  <b-form-checkbox
                    id="show-only-certain-programs"
                    v-model="programVisibility.show_only_certain_programs"
                    @change="isShowOnlyCertainProgramChanged"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>


            <b-tabs
              v-if="programVisibility.show_only_certain_programs"
              v-model="tabIndex"
              content-class="col-12 mt-1 mt-md-0"
              pills
              fill
              nav-wrapper-class="col-12 py-1 px-0 border-bottom  bg-white"
              nav-class="mb-0 bg-light text-center"
            >
              <b-tab
                v-for="(instance, index) in programsBySchoolsList"
                :key="index"
                :disabled="!instance.programs.length"
              >
                <template #title>
                  <feather-icon
                    v-if="instance.visibility"
                    icon="EyeIcon"
                    size="16"
                    class="align-middle text-success mx-1 cursor-pointer"
                    @click="changeSchoolVisibility(instance)"
                  />
                  <feather-icon
                    v-else
                    icon="EyeOffIcon"
                    size="16"
                    class="align-middle mx-1 cursor-pointer"
                    :class="!instance.programs.length ? 'text-muted' : 'text-danger'"
                    @click="changeSchoolVisibility(instance)"
                  />
                  <span class="font-weight-bold">{{ instance.school_name }}</span>
                </template>
                <draggable
                  v-model="instance.programs"
                  tag="ul"
                  handle=".handle-drag"
                  class="list-group list-group-flush"
                >
                  <b-list-group-item
                    v-for="(program, index) in instance.programs"
                    :key="index"
                  >
                    <b-row>
                      <b-col cols="12">
                        <div class="d-flex align-items-center">
                          <img
                            src="@/assets/images/icons/more-vertical-v2.svg"
                            class="handle-drag cursor-move mr-1"
                          >
                          <feather-icon
                            v-if="program.visibility"
                            icon="EyeIcon"
                            size="16"
                            class="align-middle text-body mx-1 cursor-pointer"
                            @click="program.visibility = !program.visibility"
                          />
                          <feather-icon
                            v-else
                            icon="EyeOffIcon"
                            size="16"
                            class="align-middle text-body mx-1 text-muted cursor-pointer"
                            @click="program.visibility = !program.visibility"
                          />
                          {{ program.name }}
                        </div>

                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </draggable>
                <div
                  v-if="!instance.programs.length"
                  class="no-results py-2"
                >
                  <h5>No Items Found</h5>
                </div>
              </b-tab>
            </b-tabs>


            <b-row>
              <b-col
                cols="12"
                class="mt-50"
              >
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="saveChanges"
                >
                  Save
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  :to="{ name: 'admin-users'}"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <!--/ form -->
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BBadge,
  BPagination,
  BTab,
  BTabs,
  BListGroupItem,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, min } from '@validations'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import useAdminsProfile from '@/views/admin/admins/admin-profile/useAdminsProfile'
import useAdminsList from '@/views/admin/admins/admins-list/useAdminsList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

import vSelect from 'vue-select'

export default {
  components: {
    draggable,
    BListGroupItem,
    BTabs,
    BTab,
    BPagination,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    vSelect,
    TableSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      adminId: this.$router.currentRoute.params.id || null,
      userData: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        role_id: null,
        schools_data_multiselect: [],
      },
      selectedInstances: [],
      instancesOptions: [],
      rolesOptions: [],
      programVisibility: {
        show_all: true,
        show_previous: false,
        show_only_active: false,
        show_only_certain_programs: true,
        available_programs_list: [],
      },
      tabIndex: 0,
      programsBySchoolsList: [],
      required,
      email,
      min,
    }
  },
  watch: {
    selectedInstances(val) {
      this.userData.schools_data_multiselect = val.map(item => ({
        id: item,
      }))
    },
  },
  computed: {
    updatedData() {
      let preparedData = this.userData
      preparedData.program_visibility_settings = this.programVisibility

      return preparedData
    }
  },
  setup() {
    const {
      createAdmin,
      fetchAdmin,
      updateAdmin,
      fetchAdminRolesList,
      fetchProgramsListForFewSchools,
    } = useAdminsProfile()

    const {
      fetchInstancesList,
    } = useAdminsList()

    return {
      createAdmin,
      fetchAdmin,
      updateAdmin,
      fetchInstancesList,
      fetchAdminRolesList,
      fetchProgramsListForFewSchools,
    }
  },
  async created() {
    this.isLoading = true

    if (this.adminId) {
      await this.setAdminData()
    }

    this.instancesOptions = await this.fetchInstancesList()
    this.rolesOptions = await this.fetchAdminRolesList()
    if (this.programVisibility.show_only_certain_programs && this.selectedInstances.length) {
      await this.prepareProgramsList()
    }
    this.isLoading = false
  },
  methods: {
    async isShowAllChanged(val) {
      if (val === true) {
        await this.setBaseProgramVisibilityParams()
      }
    },
    async isShowOnlyCertainProgramChanged(val) {
      if (val === true && this.selectedInstances.length) {
        await this.prepareProgramsList()
      } else {
        this.programVisibility.available_programs_list = []
      }
    },
    async selectedInstancesChanged(val) {
      if (this.programVisibility.show_only_certain_programs) {
        if (!val.length) {
          this.programsBySchoolsList = []
        } else  {
          this.programsBySchoolsList = this.programsBySchoolsList.filter(item => val.includes(item.school_id))
        }
      }

      if (val.length) {
        await this.prepareProgramsList()
      }
    },
    setBaseProgramVisibilityParams() {
      this.programVisibility.show_previous = false
      this.programVisibility.show_only_active = false
      this.programVisibility.show_only_certain_programs = false
      this.programVisibility.available_programs_list = []
    },
    changeSchoolVisibility(instance) {
      instance.visibility = !instance.visibility
      instance.programs.forEach(program => program.visibility = instance.visibility)
    },
    async prepareProgramsList() {
      this.programsBySchoolsList = []
      let programsList = []

      const queryParams = {
        selected_school_id: this.selectedInstances,
        admin_id: this.adminId,
      }

      programsList = await this.fetchProgramsListForFewSchools(queryParams)

      this.selectedInstances.forEach(schoolId => {
        const school = this.instancesOptions.find(item => item.id === schoolId)
        const programsBySchool = programsList
          .filter(item => item.school_id == schoolId)
          .sort((a, b) => a.dashboard_position - b.dashboard_position)
        this.programsBySchoolsList.push({
          school_id: school['id'],
          school_name: school['name'],
          programs: programsBySchool,
          visibility: programsBySchool.filter(item => item.visibility).length > 0
        })
      })
    },
    async setAdminData() {
      const response = await this.fetchAdmin(this.adminId)

      this.userData = {
        ...response,
        schools_data_multiselect: response.schools.map(item => ({ id: item.id })),
      }

      this.selectedInstances = response.schools.map(item => item.id)

      this.programVisibility = response.program_visibility_settings
    },
    async setAvailableProgramsList() {
      if (this.programVisibility.show_only_certain_programs) {
        this.programVisibility.available_programs_list = this.programsBySchoolsList.filter(item => item.visibility)
      }
    },
    saveChanges() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.setAvailableProgramsList()
            if (this.adminId) {
              this.updateAdminProfile()
            } else {
              this.createAdminProfile()
            }
          } else {
            reject()
          }
        })
      })
    },
    async createAdminProfile() {
      await this.createAdmin(this.updatedData).then(() => {
        // this.$router.push({ name: 'admin-users' })
      })
    },
    async updateAdminProfile() {
      await this.updateAdmin(this.updatedData).then(() => {
        // this.$router.push({ name: 'admin-users' })
      })
    },
  },
}
</script>

<style lang="scss">

</style>
